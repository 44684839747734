import { PlatformLocation } from '@angular/common';
import { fromEvent } from 'rxjs';
export var topMargin = 16;
/**
 * A service that scrolls document elements into view
 */
var ScrollService = /** @class */ (function () {
    function ScrollService(document, location) {
        var _this = this;
        this.document = document;
        this.location = location;
        // On resize, the toolbar might change height, so "invalidate" the top offset.
        fromEvent(window, 'resize').subscribe(function () { return _this._topOffset = null; });
    }
    Object.defineProperty(ScrollService.prototype, "topOffset", {
        // Offset from the top of the document to bottom of any static elements
        // at the top (e.g. toolbar) + some margin
        get: function () {
            if (!this._topOffset) {
                var toolbar_1 = this.document.querySelector('.app-toolbar');
                this._topOffset = (toolbar_1 && toolbar_1.clientHeight || 0) + topMargin;
            }
            return this._topOffset;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScrollService.prototype, "topOfPageElement", {
        get: function () {
            if (!this._topOfPageElement) {
                this._topOfPageElement = this.document.getElementById('top-of-page') || this.document.body;
            }
            return this._topOfPageElement;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Scroll to the element with id extracted from the current location hash fragment.
     * Scroll to top if no hash.
     * Don't scroll if hash not found.
     */
    ScrollService.prototype.scroll = function () {
        var hash = this.getCurrentHash();
        var element = hash
            ? this.document.getElementById(hash)
            : this.topOfPageElement;
        this.scrollToElement(element);
    };
    /**
     * Scroll to the element.
     * Don't scroll if no element.
     */
    ScrollService.prototype.scrollToElement = function (element) {
        if (element) {
            element.scrollIntoView();
            if (window && window.scrollBy) {
                // Scroll as much as necessary to align the top of `element` at `topOffset`.
                // (Usually, `.top` will be 0, except for cases where the element cannot be scrolled all the
                //  way to the top, because the viewport is larger than the height of the content after the
                //  element.)
                window.scrollBy(0, element.getBoundingClientRect().top - this.topOffset);
                // If we are very close to the top (<20px), then scroll all the way up.
                // (This can happen if `element` is at the top of the page, but has a small top-margin.)
                if (window.pageYOffset < 20) {
                    window.scrollBy(0, -window.pageYOffset);
                }
            }
        }
    };
    /** Scroll to the top of the document. */
    ScrollService.prototype.scrollToTop = function () {
        this.scrollToElement(this.topOfPageElement);
    };
    /**
     * Return the hash fragment from the `PlatformLocation`, minus the leading `#`.
     */
    ScrollService.prototype.getCurrentHash = function () {
        return decodeURIComponent(this.location.hash.replace(/^#/, ''));
    };
    return ScrollService;
}());
export { ScrollService };
